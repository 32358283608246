@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-ExtraBold.woff2') format('woff2'),
      url('../fonts/PlusJakartaSans-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Bold.woff2') format('woff2'),
      url('../fonts/PlusJakartaSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Light.woff2') format('woff2'),
      url('../fonts/PlusJakartaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Regular.woff2') format('woff2'),
      url('../fonts/PlusJakartaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Medium.woff2') format('woff2'),
      url('../fonts/PlusJakartaSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html, body, #root {
  @apply w-full h-full
}

.App {
  @apply w-full h-full container mx-auto py-5 px-2
}

.card {
  @apply border border-gray-400 bg-gray-100 bg-opacity-50 rounded-lg p-4 shadow flex flex-col gap-y-1
}

.card h3 {
  @apply text-xl font-bold mb-2
}

.card ul {
  @apply grid gap-1
}

.card-element {
  @apply rounded-lg bg-gray-200 flex items-center align-middle pl-2 border bg-opacity-50 border-gray-300 hover:bg-opacity-100 transition ease-in-out duration-100
}

.card-element p {
  @apply w-full my-1
}

.card-element span {
  @apply ml-3 text-indigo-600
}

.wrapper {
  @apply flex flex-col lg:flex-row gap-y-3 lg:gap-x-4 pb-7
}

#Words {
  @apply flex-grow
}

#Handcards {
  @apply flex-grow lg:flex-grow-0 lg:w-96
}

.card-add {
  @apply bg-indigo-100 rounded-lg p-3 border border-indigo-300
}

.card-add h6 {
  @apply font-bold text-lg
}

form {
  @apply grid gap-2
}

form div {
  @apply flex flex-col md:flex-row items-center
}

form div label {
  @apply w-full md:w-16 font-light
}

form section {
  @apply border border-indigo-300 p-3 rounded-lg flex flex-col gap-2
}

input[type=text], select {
  @apply outline-none py-1 px-2 rounded-lg focus:ring flex-grow hover:shadow focus:shadow hover:bg-gray-50
}

.btn {
  @apply outline-none bg-indigo-200 text-indigo-500 py-1 px-3 rounded-lg block focus:ring flex-grow hover:shadow focus:shadow hover:bg-indigo-500 focus:bg-indigo-500 hover:text-white focus:text-white transition ease-out duration-100 active:scale-95
}

.btn-delete {
  @apply bg-opacity-0 text-red-600 hover:bg-red-500 hover:text-white focus:bg-red-500 focus:text-white focus:ring-red-300 flex-grow-0
}

nav {
  @apply flex flex-col lg:flex-row items-center mb-3
}

nav h1 {
  @apply text-xl text-indigo-600 font-extrabold lg:text-4xl flex-grow
}

.user-panel {
  @apply bg-indigo-100 rounded-lg p-3 border border-indigo-300 flex flex-col gap-1 
}

.user-panel h6 {
  @apply font-bold
}

.user-panel p {
  @apply text-xs
}


.word-item {
  @apply rounded-lg bg-gray-200 flex flex-col pl-2 pb-1 border bg-opacity-50 border-gray-300 hover:bg-opacity-100 transition ease-in-out duration-100
}

.word-item header {
  @apply flex flex-row items-center flex-grow w-full
}

.word-item header p {
  @apply text-lg font-bold flex-grow
}

.word-item .hand {
  @apply font-bold text-indigo-500
}

.word-description {
  @apply inline flex gap-2 text-sm
}

.word-description p {
  @apply inline
}

.btn-container {
  @apply flex-row gap-2
}

.btn-container button {
  @apply flex-grow-0
}

.word-part {
  @apply flex flex-row rounded-lg p-1 bg-indigo-200 items-center gap-2
}

.word-part span {
  @apply text-indigo-500 font-bold
}

.word-part input[type=text] {
  @apply flex-grow
}

.word-part p {
  @apply w-32
}

.loading {
  @apply flex flex-col justify-center items-center text-center h-32 text-indigo-500 font-bold gap-3
}

.loading svg {
  @apply animate-spin h-10 w-10 mr-3 text-indigo-500
}

.loading svg circle {
  @apply opacity-25
}

.loading svg path {
  @apply opacity-75  
}
